(function () {
  'use strict';

  angular
  .module('neo.home.informes')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.informes', {
      url: '/informes/{dia}/{mes}/{any}/{dia2}/{mes2}/{any2}/{idperfil}',
     
          templateUrl: 'home/informes/informes.tpl.html',
          controller: 'informesCtrl',
          controllerAs: 'vm'
        ,
      resolve:{
          partits: function ( partits,$stateParams){
          return partits.query({dia :  $stateParams.dia,mes :  $stateParams.mes,any :  $stateParams.any
          ,dia2 :  $stateParams.dia2,mes2 :  $stateParams.mes2,any2 :  $stateParams.any2,idperfil :  $stateParams.idperfil}).$promise;
    }
    
      }
    }); }
}());
